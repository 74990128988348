import { useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import AppContext from "./context/AppContext";
import theme from "./theme";
import Auth from "./pages/Auth/Auth";
import { createAWSInstance } from "./api/awsConfig";
import { Iot, IotData } from "aws-sdk";
import MachinesView from "./pages/Machines/MachinesView";
import MachineView from "./pages/Machine/MachineView";

interface UserCredentials {
	accessKey: string;
	secretAccessKey: string;
	sessionToken?: string;
}

const App = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userCreds, setUserCreds] = useState<UserCredentials | null>(null);
	const [awsInstanceIot, setAwsInstanceIot] = useState<Iot | null>(null);
	const [awsInstanceIotData, setAwsInstanceIotData] = useState<IotData | null>(
		null
	);

	const handleLogin = (credentials: UserCredentials) => {
		setUserCreds(credentials);
		setIsLoggedIn(true);

		if (credentials != null) {
			const awsInstance = createAWSInstance(credentials);

			const iot = new awsInstance.Iot();
			const iotData = new awsInstance.IotData({
				// endpoint: `https://${process.env.REACT_APP_AWS_IOT_ENDPOINT}`,
				endpoint: `https://a3eoleyjeqiuse-ats.iot.eu-central-1.amazonaws.com`,
			});

			// const iotData = new awsInstance.IotData({
			// 	endpoint: `https://${process.env.REACT_APP_AWS_IOT_ENDPOINT}`,
			// });

			setAwsInstanceIot(iot);
			setAwsInstanceIotData(iotData);
		}
	};

	const handleLogout = () => {
		setUserCreds(null);
		setIsLoggedIn(false);
		setAwsInstanceIot(null);
		setAwsInstanceIotData(null);
	};

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Box className="App">
					<AppContext.Provider value={{ awsInstanceIot, awsInstanceIotData }}>
						<Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
						<Box
							component="main"
							sx={{ width: { md: "90%", lg: "75%" }, margin: "auto" }}
						>
							<Routes>
								<Route path="/auth" element={<Auth onLogin={handleLogin} />} />
								<Route
									path="/"
									element={
										isLoggedIn ? <MachinesView /> : <Navigate to="/auth" />
									}
								/>
								<Route
									path="/machineConfiguration/:thingName"
									element={
										isLoggedIn ? <MachineView /> : <Navigate to="/auth" />
									}
								/>
							</Routes>
						</Box>
					</AppContext.Provider>
				</Box>
			</Router>
		</ThemeProvider>
	);
};

export default App;

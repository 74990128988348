import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { DeviceThing } from '../api/api'

export const useSearch = (machines: DeviceThing[]): DeviceThing[] => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("search") || "";

    return useMemo(() => {
        return machines.filter(machine => {
            const instance = machine.instance?.toLowerCase() || "";
            const thingName = machine.thingName?.toLowerCase() || "";
            const version = machine.version?.toString() || "";
            return (
                instance.includes(searchQuery.toLowerCase()) ||
                thingName.includes(searchQuery.toLowerCase()) ||
                version.includes(searchQuery)
            );
        });
    }, [machines, searchQuery]);
};

import { Box, Button, Card, Typography, CardActions, Tab, SelectChangeEvent } from '@mui/material'
import './MachineConfiguration.css'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useParams } from 'react-router-dom'
import { updateDeviceShadow } from '../../api/machinesApi'
import { DeviceThing } from '../../api/api'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useMachinesContext } from '../../context/MachinesContext'
import { useAppContext } from '../../context/AppContext'
import MachineTab from '../../components/Tabs/MachineTab'
import { FormState } from '../../interfaces/index'
import NetworkTab from '../../components/Tabs/NetworkTab'
import ApplicationTab from '../../components/Tabs/ApplicationTab'

const MachineConfiguration = () => {
    const { thingName } = useParams()
    const { machines, isLoading } = useMachinesContext()
    const { awsInstanceIot, awsInstanceIotData } = useAppContext()

    const [currentTab, setCurrentTab] = useState('1')
    const [machine, setMachine] = useState<DeviceThing>()
    const [isSaved, setIsSaved] = useState<boolean>(true)

    const [formState, setFormState] = useState<FormState>({
        datasyncd: {
            sync: {
                syncInterval: null,
                monthsBack: null,
                storageDir: '',
                dbPath: '',
                fetchLimit: null,
            },
        },
        connman: {
            ethernet: {
                ipAddress: '',
                macAddress: '',
                ethernet: false,
                dhcp: false,
            },
        },
        vend: {
            lang: {
                language: '',
                translation: false,
            },
            session: {
                enableUserLogin: false,
                enableUserAuth: false,
                authUserInput: false,
                userDefaultView: '',
                showInfoAfterLogin: false,
                shortInactivityTimeout: null,
                longInactivityTimeout: null,
                switchUserLanguage: false,
                enableUserAutoLogin: false,
                showContinuationQuestion: false,
                continuationQuestionTimeout: null,
            },
            loading: {
                hideLoadingFlaps: false,
                hideLoadingGroup: false,
                hideLoadingCell: false,
                setCellSchema: false,
                showOnlyAssignedProducts: false,
                preventLoadingCells: false,
                doNotUseDefined: false,
                showOnlyAssignedProductItems: false,
                showTreeStructure: false,
                showOnlyAssignedToUser: false,
                showZeroProd: false,
            },
            distribution: {
                showZeroProd: false,
                productFilterLevel: '',
                showProdCard: false,
                showAllCostCenters: false,
                distributionWithCost: false,
                forcePartialCollection: false,
                forceUnlimitedAccess: false,
                distributionWithBasket: false,
                basketSize: null,
                showLastDistributions: '',
                userProductLimit: false,
                showAvailableQuantity: false,
                showContinuationQuestion: false,
                continuationQuestionTimeout: null,
                showTreeStructure: false,
                showOnlyAssigned: false,
                switchFilters: false,
                flapCloseTime: null,
            },
            return: {
                returnsEnabled: false,
                allowReturnsToCells: false,
                allowReturnsToLargerCells: false,
                canReturnOnlyService: false,
                canReturnOnlyRentable: false,
                forcePartialReturn: false,
            },
            cells: {
                cellsLabeling: '',
                canReturnService: false,
                skipSelectionLabel: false,
            },
            products: {
                productItemsEnabled: false,
                detachItem: false,
                itemsSingleUse: false,
                skipSelection: false,
                showOnlyAssigned: false,
            },
            distributionRequest: {
                distributionRequestEnabled: false,
                requestValidDays: null,
                enableOwnManagement: false,
                filterLocalRequests: false,
                userAuthRealization: false,
                autoAcceptEnabled: false,
                autoAcceptInterval: null,
            },
            integration: {
                vsdEnable: false,
                pipeLockPath: '',
                pipeUnlockPath: '',
                lockFilesPath: '',
            },
            frontend: {
                idleScreenType: '',
                slideshowPeriod: null,
                slidesPath: '',
                hideInactiveMenu: false,
                productListColumn: '',
                productCatalogColumn: '',
                productCatalogRow: '',
                transactionsListColumn: null,
                transactionsListRow: null,
                usersListColumn: null,
                usersListRow: null,
                showUserNames: false,
                switchUserLanguage: false,
                patternFilterDelay: null,
                dpiResolution: null,
                showCacheUpdate: false,
                hideProductCatalog: false,
                imagesPath: '',
                showFpsCounter: false,
            },
            backend: {
                noCanInterface: false,
                cellAccessTimeLimit: null,
                cellOpeningTimeLimit: null,
                checkBeforeClosingCells: false,
                lockOnIllegalOpen: false,
                logNewTransactions: false,
                flexEjectionTimeout: null,
            },
            other: {
                cellReplenishment: false,
                inventoryTransfer: false,
            },
        },
    })

    useEffect(() => {
        if (!thingName || !machines || machines.length <= 0) return

        let machine = machines.find((machine) => machine.thingName === thingName)

        if (!machine) return

        setMachine(machine)
        setFormState({
            ...formState,
            datasyncd: machine.datasyncd,
            connman: machine.connman,
            vend: machine.vend,
        })
    }, [machines])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target
        const [category, subCategory, field] = name.split('.')

        setFormState((prevState) => ({
            ...prevState,
            [category]: {
                ...(prevState[category as keyof FormState] as Record<string, any>),
                [subCategory]: {
                    ...((prevState[category as keyof FormState] as Record<string, any>)[subCategory] as Record<string, any>),
                    [field]: type === 'checkbox' ? e.target.checked : value,
                },
            },
        }))

        setIsSaved(false)
    }

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target
        const [category, subCategory, field] = name.split('.')

        setFormState((prevState) => ({
            ...prevState,
            [category]: {
                ...(prevState[category as keyof FormState] as Record<string, any>),
                [subCategory]: {
                    ...((prevState[category as keyof FormState] as Record<string, any>)[subCategory] as Record<string, any>),
                    [field]: value,
                },
            },
        }))

        setIsSaved(false)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue)
    }

    const handleSubmit = async (event: React.SyntheticEvent) => {
        if (!machine) return
        let currentShadowName = 'datasyncd'
        switch (currentTab) {
            case '1':
                currentShadowName = 'datasyncd'
                machine.datasyncd = formState.datasyncd
                break
            case '2':
                currentShadowName = 'connman'
                machine.connman = formState.connman
                break
            case '3':
                currentShadowName = 'vend'
                machine.vend = formState.vend
                break
            default:
                currentShadowName = 'datasyncd'
                break
        }

        const updateResult = await updateDeviceShadow(machine, currentShadowName, awsInstanceIotData!)

        if (updateResult && updateResult.success) {
            toast.success('Zmiany zapisane pomyślnie')
        } else {
            toast.error('Wystąpił problem podczas zapisywania zmian')
        }
        setIsSaved(true)
    }

    if (machine === undefined) {
        return <Box>Wczytywanie...</Box>
    }

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: { xs: 0, md: 3 },
                    paddingTop: { xs: 1, md: 4 },
                    paddingLeft: { xs: 2, md: 0 },
                }}
            >
                <Typography variant="body2" sx={{ color: '#666', fontSize: 12, textDecoration: 'none' }}>
                    <RouterLink to="/" style={{ textDecoration: 'none', color: '#666' }}>
                        Lista maszyn&nbsp;/&nbsp;
                    </RouterLink>
                </Typography>

                <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Panel konfiguracyjny
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gap: 6,
                    gridTemplateColumns: { xs: '1fr', md: '1fr 3fr' },
                    padding: { xs: 2, md: 0 },
                }}
            >
                <Card sx={{ padding: 4, height: '50%' }} className="card-info" variant="outlined">
                    <Box>
                        <Typography variant="body2" className="text-subheader">
                            Instancja
                        </Typography>
                        <Typography variant="body1">{machine.instance}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" className="text-subheader">
                            Numer seryjny
                        </Typography>
                        <Typography variant="body1">{machine.thingName}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" className="text-subheader">
                            Wersja oprogramowania
                        </Typography>
                        <Typography variant="body1">v{machine.version}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" className="text-subheader">
                            Ostatnia aktualizacja maszyny
                        </Typography>
                        <Typography variant="body1">{machine.lastUpdateString}</Typography>
                    </Box>
                </Card>
                <Box>
                    <TabContext value={currentTab}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Maszyna" value="1" className="card-tab" sx={{ color: '#000' }} />
                            <Tab label="Sieć" value="2" className="card-tab" sx={{ color: '#000' }} />
                            <Tab label="Aplikacja" value="3" className="card-tab" sx={{ color: '#000' }} />
                        </TabList>
                        <Card sx={{ padding: '20px 24px 16px 15px', height: '60vh' }} className="card-configuration" variant="outlined">
                            <TabPanel value="1" sx={{ p: 0, height: '88%' }}>
                                <MachineTab formState={formState} handleInputChange={handleInputChange} />
                            </TabPanel>
                            <TabPanel value="2" sx={{ p: 0, height: '88%' }}>
                                <NetworkTab formState={formState} handleInputChange={handleInputChange} />
                            </TabPanel>
                            <TabPanel value="3" sx={{ p: 0, height: '86%' }}>
                                <ApplicationTab
                                    formState={formState}
                                    handleInputChange={handleInputChange}
                                    handleSelectChange={handleSelectChange}
                                />
                            </TabPanel>

                            <CardActions
                                sx={{
                                    p: 0,
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={isSaved}
                                    color="primary"
                                    sx={{
                                        color: '#fff',
                                        borderRadius: 2,
                                        boxShadow: 'none',
                                        p: '0.4rem 1.7rem',
                                        textTransform: 'none',
                                        fontSize: 16,
                                    }}
                                >
                                    Zapisz zmiany
                                </Button>
                                <ToastContainer
                                    position="bottom-right"
                                    autoClose={4000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="colored"
                                />
                            </CardActions>
                        </Card>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    )
}

export default MachineConfiguration

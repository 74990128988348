import React from "react";
import { Card, CardContent, Box, Tab } from "@mui/material";
import { TabList, TabPanel } from "@mui/lab";
import { FormState } from "../../interfaces/index";
import TextRow from "../Rows/TextRow";
import NumberRow from "../Rows/NumberRow";

interface TabProps {
	formState: FormState;
	handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MachineTab = ({ formState, handleInputChange }: TabProps) => {
	return (
		<Box className="sub-card-container" sx={{ height: "100%" }}>
			<TabList orientation="vertical" sx={{ width: "174px" }}>
				<Tab
					label="Urządzenie"
					value="3"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="IDS"
					value="2"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="Synchronizacja"
					value="1"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="VSD"
					value="4"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
			</TabList>
			<Card
				sx={{ width: "77%", p: 0 }}
				variant="outlined"
				className="card-configuration"
			>
				<CardContent sx={{ p: 2 }} className="card-configuration">
					<TabPanel value="1" sx={{ p: 0 }} className="configuration-option">
						<NumberRow
							label="Interwał synchronizacji w sekundach"
							caption="SYNCD_INTERVAL_SEC"
							name="datasyncd.sync.syncInterval"
							value={formState.datasyncd.sync.syncInterval}
							onChange={handleInputChange}
						/>

						<NumberRow
							label="Ilość miesięcy przechowywania transakcji"
							caption="SYNCD_MONTHSBACK"
							name="datasyncd.sync.monthsBack"
							value={formState.datasyncd.sync.monthsBack}
							onChange={handleInputChange}
						/>

						<NumberRow
							label="Limit pobierania RESTAPI"
							caption="RESTAPI_FETCH_LIMIT"
							name="datasyncd.sync.fetchLimit"
							value={formState.datasyncd.sync.fetchLimit}
							onChange={handleInputChange}
						/>

						<TextRow
							label="Ścieżka do zdjęć"
							caption="SYNCD_STORAGEDIR"
							name="datasyncd.sync.storageDir"
							value={formState.datasyncd.sync.storageDir}
							onChange={handleInputChange}
						/>
						<TextRow
							label="Ścieżka do bazy danych"
							caption="DB_SQLITE_PATH"
							name="datasyncd.sync.dbPath"
							value={formState.datasyncd.sync.dbPath}
							onChange={handleInputChange}
						/>
					</TabPanel>
				</CardContent>
			</Card>
		</Box>
	);
};

export default MachineTab;

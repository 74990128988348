import { Box } from "@mui/material";
import MachinesContext from "./../../context/MachinesContext";
import { useFetchIoTDevices } from "./../../hooks/useFetchIoTDevices";
import { Fragment } from "react";
import MachineConfiguration from "../MachineConfiguration/MachineConfiguration";

const MachineView = () => {
	const [machines, isLoading] = useFetchIoTDevices();

	return (
		<Box className="machineView">
			<MachinesContext.Provider value={{ machines, isLoading }}>
				<Fragment>
					<MachineConfiguration />
				</Fragment>
			</MachinesContext.Provider>
		</Box>
	);
};

export default MachineView;

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface UserCredentials {
	accessKey: string;
	secretAccessKey: string;
	sessionToken?: string;
}

interface AuthProps {
	onLogin: (userCreds: UserCredentials) => void;
}

export default function Auth({ onLogin }: AuthProps) {
	const navigate = useNavigate();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const userCreds: UserCredentials = {
			accessKey: data.get("accessKey")?.toString()!,
			secretAccessKey: data.get("secretAccessKey")?.toString()!,
			sessionToken: data.get("sessionToken")?.toString(),
		};

		onLogin(userCreds);

		navigate(`/`);
	};

	return (
		<Card
			sx={{
				padding: 5,
				width: 400,
				height: 400,
				margin: "auto",
				marginTop: 10,
			}}
			variant="outlined"
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Autoryzacja
				</Typography>

				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="accessKey"
						label="AWS ACCESS KEY ID"
						name="accessKey"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="secretAccessKey"
						label="AWS SECRET ACCESS KEY"
						type="password"
						id="secretAccessKey"
						autoComplete="current-password"
					/>
					<TextField
						margin="normal"
						fullWidth
						name="sessionToken"
						label="AWS SESSION TOKEN"
						type="password"
						id="sessionToken"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							backgroundColor: "primary.main",
							color: "secondary.main",
						}}
					>
						Zaloguj
					</Button>
				</Box>
			</Box>
		</Card>
	);
}

import React, { useState } from 'react'
import { Card, CardContent, Box, Tab, SelectChangeEvent } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FormState } from '../../interfaces/index'
import SwitchRow from '../Rows/SwitchRow'
import NumberRow from '../Rows/NumberRow'
import ListRow from '../Rows/ListRow'
import TextRow from '../Rows/TextRow'

interface TabProps {
    formState: FormState
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleSelectChange: (event: SelectChangeEvent<string>) => void
}

const ApplicationTab = ({ formState, handleInputChange, handleSelectChange }: TabProps) => {
    const [currentApplicationTab, setCurrentApplicationTab] = useState('1')

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentApplicationTab(newValue)
    }

    return (
        <Box className="sub-card-container" sx={{ height: '100%' }}>
            <TabContext value={currentApplicationTab}>
                <TabList onChange={handleTabChange} orientation="vertical" sx={{ width: '174px', height: '120%' }}>
                    <Tab label="Język" value="1" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Sesja użytkownika" value="2" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Ładowanie" value="3" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Dystrybucja" value="4" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Zwrot" value="5" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Etykietowanie komórek" value="6" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Egzemplarze produktów" value="7" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Żądanie dystrybucji" value="8" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Integracja VSD" value="9" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Frontend" value="10" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Backend" value="11" className="sub-card-tab" sx={{ color: '#858585' }} />
                    <Tab label="Inne" value="12" className="sub-card-tab" sx={{ color: '#858585' }} />
                </TabList>
                <Card sx={{ width: '77%', p: 0 }} variant="outlined" className="card-configuration">
                    <CardContent sx={{ p: 2 }} className="card-configuration">
                        <TabPanel value="1" sx={{ p: 0 }} className="configuration-option">
                            <ListRow
                                label="Wybierz język"
                                caption="LANGUAGE"
                                name="vend.lang.language"
                                value={formState.vend.lang.language}
                                onChange={handleSelectChange}
                                items={['PL', 'EN', 'DE']}
                            />

                            <SwitchRow
                                label="Wyświetlaj tekst z wielkich liter"
                                caption="TRANSLATION_ENFORCE_UPPER_CASE"
                                switchName="vend.lang.translation"
                                checked={formState.vend.lang.translation}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Włącz logowanie tylko dla przypisanych użytkowników"
                                caption="?"
                                switchName="vend.session.enableUserLogin"
                                checked={formState.vend.session.enableUserLogin}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Włącz autoryzację użytkownika bez PIN-u"
                                caption="UI_ENABLE_USER_AUTH_WITHOUT_PIN"
                                switchName="vend.session.enableUserAuth"
                                checked={formState.vend.session.enableUserAuth}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Ukryj tekst podczas logowania"
                                caption="UI_AUTH_USER_INPUT_TEXT_HIDDEN"
                                switchName="vend.session.authUserInput"
                                checked={formState.vend.session.authUserInput}
                                onChange={handleInputChange}
                            />

                            <ListRow
                                label="Domyślny ekran po zalogowaniu użytkownika"
                                caption="UI_US_DEF_VIEW"
                                name="vend.session.userDefaultView"
                                value={formState.vend.session.userDefaultView}
                                onChange={handleSelectChange}
                                items={['main menu', 'product catalog']}
                            />

                            <SwitchRow
                                label="Pokaż informacje o użytkowniku po zalogowaniu"
                                caption="UI_SHOW_INFO_AFTER_LOGIN"
                                switchName="vend.session.showInfoAfterLogin"
                                checked={formState.vend.session.showInfoAfterLogin}
                                onChange={handleInputChange}
                            />

                            <NumberRow
                                label="Krótki czas bezczynności użytkownika"
                                caption="UI_SHORT_INACTIVITY_TIMEOUT"
                                name="vend.session.shortInactivityTimeout"
                                value={formState.vend.session.shortInactivityTimeout}
                                onChange={handleInputChange}
                            />

                            <NumberRow
                                label="Długi czas bezczynności użytkownika"
                                caption="UI_LONG_INACTIVITY_TIMEOUT"
                                name="vend.session.longInactivityTimeout"
                                value={formState.vend.session.longInactivityTimeout}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Zmień język użytkownika"
                                caption="UI_SWITCH_USER_LANGUAGE"
                                switchName="vend.session.switchUserLanguage"
                                checked={formState.vend.session.switchUserLanguage}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Automatyczne logowanie aktywnego użytkownika"
                                caption="UI_ENABLE_USER_AUTO_LOGIN_BASED_ON_SESSION_FILE"
                                switchName="vend.session.enableUserAutoLogin"
                                checked={formState.vend.session.enableUserAutoLogin}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż pytanie o kontynuację sesji"
                                caption="UI_SHOW_AFTER_SUCCESSFUL_DISTRIBUTION_CONTINUATION_QUESTION"
                                switchName="vend.session.showContinuationQuestion"
                                checked={formState.vend.session.showContinuationQuestion}
                                onChange={handleInputChange}
                            />

                            {formState.vend.session.showContinuationQuestion && (
                                <NumberRow
                                    label="Limit czasu kontynuacji sesji"
                                    caption="UI_AFTER_SUCCESSFUL_DISTRIBUTION_CONTINUATION_QUESTION_TIMEOUT"
                                    name="vend.session.continuationQuestionTimeout"
                                    value={formState.vend.session.continuationQuestionTimeout}
                                    onChange={handleInputChange}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Ukryj ładowanie przez klapy"
                                caption="UI_HIDE_LOADING_THROUGH_FLAPS"
                                switchName="vend.loading.hideLoadingFlaps"
                                checked={formState.vend.loading.hideLoadingFlaps}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Ukryj ładowanie grup produktów"
                                caption="UI_HIDE_LOADING_GROUP_OF_PRODUCTS"
                                switchName="vend.loading.hideLoadingGroup"
                                checked={formState.vend.loading.hideLoadingGroup}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Ukryj ładowanie za pomocą skanera"
                                caption="UI_HIDE_LOADING_WITH_CELL_SCANNER"
                                switchName="vend.loading.hideLoadingCell"
                                checked={formState.vend.loading.hideLoadingCell}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Ustaw schemat komórki podczas ładowania"
                                caption="VM_SET_CELL_SCHEMA_WHILE_LOADING"
                                switchName="vend.loading.setCellSchema"
                                checked={formState.vend.loading.setCellSchema}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż tylko przypisane produkty podczas ładowania"
                                caption="UI_SHOW_ONLY_ASSIGNED_PRODUCTS_WHILE_LOADING"
                                switchName="vend.loading.showOnlyAssignedProducts"
                                checked={formState.vend.loading.showOnlyAssignedProducts}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Zapobiegaj ładowaniu komórek z schematem"
                                caption="VM_PREVENT_LOADING_CELLS_WITH_SCHEMA"
                                switchName="vend.loading.preventLoadingCells"
                                checked={formState.vend.loading.preventLoadingCells}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Zignoruj zdefiniowane multipaki produktów"
                                caption="UI_DO_NOT_USE_DEFINED_MULTIPACKS"
                                switchName="vend.loading.doNotUseDefined"
                                checked={formState.vend.loading.doNotUseDefined}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż tylko przypisane do punktu produkty podczas ładowania"
                                caption="UI_SHOW_ONLY_ASSIGNED_PRODUCT_ITEMS_WHILE_LOADING"
                                switchName="vend.loading.showOnlyAssignedProductItems"
                                checked={formState.vend.loading.showOnlyAssignedProductItems}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż strukturę drzewa podczas ładowania"
                                caption="UI_SHOW_TREE_STRUCTURE_ON_LOADING"
                                switchName="vend.loading.showTreeStructure"
                                checked={formState.vend.loading.showTreeStructure}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż tylko przypisane do użytkownika produkty podczas ładowania"
                                caption="UI_SHOW_ONLY_ASSIGNED_TO_USER_ON_LOADING"
                                switchName="vend.loading.showOnlyAssignedToUser"
                                checked={formState.vend.loading.showOnlyAssignedToUser}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż produkty o zerowej ilości podczas ładowania"
                                caption="UI_SHOW_ZERO_PROD_ON_LOADING"
                                switchName="vend.loading.showZeroProd"
                                checked={formState.vend.loading.showZeroProd}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="4" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Pokaż produkty o zerowej ilości"
                                caption="UI_SHOW_ZERO_PROD"
                                switchName="vend.distribution.showZeroProd"
                                checked={formState.vend.distribution.showZeroProd}
                                onChange={handleInputChange}
                            />

                            <ListRow
                                label="Poziom filtracji produktów"
                                caption="UI_PRODUCT_FILTER_LEVEL"
                                name="vend.distribution.productFilterLevel"
                                value={formState.vend.distribution.productFilterLevel}
                                onChange={handleSelectChange}
                                items={['under limit', 'over limit', 'all']}
                            />

                            <SwitchRow
                                label="Pokaż dodatkową kartę produktu "
                                caption="UI_SHOW_PROD_CARD"
                                switchName="vend.distribution.showProdCard"
                                checked={formState.vend.distribution.showProdCard}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż wszystkie centra kosztów "
                                caption="UI_SHOW_ALL_COST_CENTERS"
                                switchName="vend.distribution.showAllCostCenters"
                                checked={formState.vend.distribution.showAllCostCenters}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Dystrybucja z zestawami centrów kosztów "
                                caption="VM_DISTRIBUTION_WITH_COST_CENTERS_SETS"
                                switchName="vend.distribution.distributionWithCost"
                                checked={formState.vend.distribution.distributionWithCost}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Wymuś częściowe zbieranie "
                                caption="UI_FORCE_PARTIAL_COLLECTION"
                                switchName="vend.distribution.forcePartialCollection"
                                checked={formState.vend.distribution.forcePartialCollection}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Wymuś nieograniczoną dystrybucję "
                                caption="UI_FORCE_UNLIMITED_ACCESS_PROFILE"
                                switchName="vend.distribution.forceUnlimitedAccess"
                                checked={formState.vend.distribution.forceUnlimitedAccess}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Włącz koszyk dystrybucji "
                                caption="UI_DISTRIBUTION_WITH_BASKET"
                                switchName="vend.distribution.distributionWithBasket"
                                checked={formState.vend.distribution.distributionWithBasket}
                                onChange={handleInputChange}
                            />
                            {formState.vend.distribution.distributionWithBasket && (
                                <NumberRow
                                    label="Rozmiar koszyka"
                                    caption="UI_BASKET_SIZE"
                                    name="vend.distribution.basketSize"
                                    value={formState.vend.distribution.basketSize}
                                    onChange={handleInputChange}
                                />
                            )}

                            <ListRow
                                label="Pokaż ostatnią dystrybucję produktu"
                                caption="UI_SHOW_LAST_DISTRIBUTIONS_OF_PRODUCT"
                                name="vend.distribution.showLastDistributions"
                                value={formState.vend.distribution.showLastDistributions}
                                onChange={handleSelectChange}
                                items={['yes', 'no', 'only for rentable']}
                            />

                            <SwitchRow
                                label="Limit produktów użytkownika bez zwrotów"
                                caption="UI_USER_PRODUCT_LIMIT_WITHOUT_RETURNS"
                                switchName="vend.distribution.userProductLimit"
                                checked={formState.vend.distribution.userProductLimit}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż dostępną ilość w katalogu produktów "
                                caption="UI_SHOW_AVAILABLE_QUANTITY_IN_ PRODUCT_CATALOG_WHILE_DISTRIBUTION"
                                switchName="vend.distribution.showAvailableQuantity"
                                checked={formState.vend.distribution.showAvailableQuantity}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż pytanie o kontynuację po dystrybucji "
                                caption="UI_SHOW_AFTER_SUCCESSFUL_ DISTRIBUTION_CONTINUATION_QUESTION"
                                switchName="vend.distribution.showContinuationQuestion"
                                checked={formState.vend.distribution.showContinuationQuestion}
                                onChange={handleInputChange}
                            />

                            <NumberRow
                                label="Limit czasu na następne pytanie o dystrybucję"
                                caption="UI_AFTER_SUCCESSFUL_DISTRIBUTION _CONTINUATION_QUESTION_TIMEOUT"
                                name="vend.distribution.continuationQuestionTimeout"
                                value={formState.vend.distribution.continuationQuestionTimeout}
                                onChange={handleInputChange}
                            />

                            <SwitchRow
                                label="Pokaż strukturę drzewa produktów podczas dystrybucji "
                                caption="UI_SHOW_TREE_STRUCTURE_ON_DISTRIBUTION"
                                switchName="vend.distribution.showTreeStructure"
                                checked={formState.vend.distribution.showTreeStructure}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż tylko przypisane produkty do punktu dystrybucji podczas dystrybucji "
                                caption="UI_SHOW_ONLY_ASSIGNED_TO_DP_ON_DISTRIBUTION"
                                switchName="vend.distribution.showOnlyAssigned"
                                checked={formState.vend.distribution.showOnlyAssigned}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Włącz przełączanie filtrów podczas dystrybucji "
                                caption="UI_SWITCHING_FILTERS_ON_DISTRIBUTION"
                                switchName="vend.distribution.switchFilters"
                                checked={formState.vend.distribution.switchFilters}
                                onChange={handleInputChange}
                            />

                            <NumberRow
                                label="Czas oczekiwania na zamknięcie klapki"
                                caption="UI_AFTER_SUCCESSFUL_DISTRIBUTION _CONTINUATION_QUESTION_TIMEOUT"
                                name="vend.distribution.flapCloseTime"
                                value={formState.vend.distribution.flapCloseTime}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="5" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Obsługa zwrotów"
                                caption="VM_RETURNS_ENABLED"
                                switchName="vend.return.returnsEnabled"
                                checked={formState.vend.return.returnsEnabled}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Zwroty do komórek z różnymi schematami wielkości"
                                caption="VM_ALLOW_RETURNS_TO_CELLS_WITH _SCHEMA_WITH_DIFFERENT_SIZE"
                                switchName="vend.return.allowReturnsToCells"
                                checked={formState.vend.return.allowReturnsToCells}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Zwrot do większych komórek"
                                caption="VM_ALLOW_RETURNS_TO_LARGER_CELLS"
                                switchName="vend.return.allowReturnsToLargerCells"
                                checked={formState.vend.return.allowReturnsToLargerCells}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Zwrot tylko dla produktów z etykietą “service required”"
                                caption="UI_CAN_RETURN_ONLY_SERVICE_REQUIRED"
                                switchName="vend.return.canReturnOnlyService"
                                checked={formState.vend.return.canReturnOnlyService}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Zwrot tylko dla produktów możliwych do wynajęcia"
                                caption="UI_CAN_RETURN_ONLY_RENTABLE"
                                switchName="vend.return.canReturnOnlyRentable"
                                checked={formState.vend.return.canReturnOnlyRentable}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Wymuś częściowy zwrot"
                                caption="UI_FORCE_PARTIAL_RETURN"
                                switchName="vend.return.forcePartialReturn"
                                checked={formState.vend.return.forcePartialReturn}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="6" sx={{ p: 0 }} className="configuration-option">
                            <ListRow
                                label="Etykietowanie komórek "
                                caption="VM_CELLS_LABELING"
                                name="vend.cells.cellsLabeling"
                                value={formState.vend.cells.cellsLabeling}
                                onChange={handleSelectChange}
                                items={['no', 'schema', 'allowed', 'mandatory']}
                            />
                            <SwitchRow
                                label="Zwrot tylko dla etykiet “service required”"
                                caption="UI_CAN_RETURN_ONLY_SERVICE_REQUIRED"
                                switchName="vend.cells.canReturnService"
                                checked={formState.vend.cells.canReturnService}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pomiń wybór dla pojedynczej etykiety"
                                caption="UI_SKIP_SELECTION_FOR_SOLE_LABEL"
                                switchName="vend.cells.skipSelectionLabel"
                                checked={formState.vend.cells.skipSelectionLabel}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="7" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Egzemplarze produktów"
                                caption="VM_PRODUCT_ITEMS_ENABLED"
                                switchName="vend.products.productItemsEnabled"
                                checked={formState.vend.products.productItemsEnabled}
                                onChange={handleInputChange}
                            />
                            {formState.vend.products.productItemsEnabled && (
                                <React.Fragment>
                                    <SwitchRow
                                        label="Oddziel egzemplarze produktu po dystrybucji"
                                        caption="VM_DETACH_PRODUCT_ITEM_AFTER_DISTRIBUTION"
                                        switchName="vend.products.detachItem"
                                        checked={formState.vend.products.detachItem}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Pojedyncze użycie egzemplarzy produktów"
                                        caption="VM_PRODUCT_ITEMS_SINGLE_USE"
                                        switchName="vend.products.itemsSingleUse"
                                        checked={formState.vend.products.itemsSingleUse}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Pomiń zaznaczenie dla pojedynczych produktów"
                                        caption="UI_SKIP_SELECTION_FOR_SOLE_ITEM"
                                        switchName="vend.products.skipSelection"
                                        checked={formState.vend.products.skipSelection}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Pokaż tylko przypisane produkty podczas ładowania"
                                        caption="UI_SHOW_ONLY_ASSIGNED_PRODUCT_ITEMS_WHILE_LOADING"
                                        switchName="vend.products.showOnlyAssigned"
                                        checked={formState.vend.products.showOnlyAssigned}
                                        onChange={handleInputChange}
                                    />
                                </React.Fragment>
                            )}
                        </TabPanel>
                        <TabPanel value="8" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Żądanie dystrybucji"
                                caption="VM_DISTRIBUTION_REQUEST_ENABLED"
                                switchName="vend.distributionRequest.distributionRequestEnabled"
                                checked={formState.vend.distributionRequest.distributionRequestEnabled}
                                onChange={handleInputChange}
                            />

                            {formState.vend.distributionRequest.distributionRequestEnabled && (
                                <React.Fragment>
                                    <NumberRow
                                        label="Długość ważności"
                                        caption="VM_DISTRIBUTION_REQUEST_VALID_DAYS"
                                        name="vend.distributionRequest.requestValidDays"
                                        value={formState.vend.distributionRequest.requestValidDays}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Własne zarządzanie żądaniami"
                                        caption="VM_ENABLE_OWN_DR_MANAGEMENT"
                                        switchName="vend.distributionRequest.enableOwnManagement"
                                        checked={formState.vend.distributionRequest.enableOwnManagement}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Pozwolenie na filtrowanie lokalnych żądań"
                                        caption="UI_FILTER_LOCAL_DISTRIBUTION_REQUESTS"
                                        switchName="vend.distributionRequest.filterLocalRequests"
                                        checked={formState.vend.distributionRequest.filterLocalRequests}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Dodatkowa autoryzacja użytkownika podczas realizacji żądania"
                                        caption="UI_USER_AUTH_AT_DR_REALIZATION"
                                        switchName="vend.distributionRequest.userAuthRealization"
                                        checked={formState.vend.distributionRequest.userAuthRealization}
                                        onChange={handleInputChange}
                                    />
                                    <SwitchRow
                                        label="Automatyczna finalizacja żądań"
                                        caption="VM_DR_AUTO_ACCEPT_ENABLED"
                                        switchName="vend.distributionRequest.autoAcceptEnabled"
                                        checked={formState.vend.distributionRequest.autoAcceptEnabled}
                                        onChange={handleInputChange}
                                    />
                                    {formState.vend.distributionRequest.autoAcceptEnabled && (
                                        <NumberRow
                                            label="Interwał czasowy automatycznej finalizacji żądań"
                                            caption="VM_DR_AUTO_ACCEPT_INTERVAL"
                                            name="vend.distributionRequest.autoAcceptInterval"
                                            value={formState.vend.distributionRequest.autoAcceptInterval}
                                            onChange={handleInputChange}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </TabPanel>
                        <TabPanel value="9" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Własne zarządzanie żądaniami"
                                caption="VSD_ENABLE"
                                switchName="vend.integration.vsdEnable"
                                checked={formState.vend.integration.vsdEnable}
                                onChange={handleInputChange}
                            />

                            {formState.vend.integration.vsdEnable && (
                                <React.Fragment>
                                    <TextRow
                                        label="Pozwolenie na filtrowanie lokalnych żądań"
                                        caption="VSD_PIPE_LOCK_PATH"
                                        name="vend.integration.pipeLockPath"
                                        value={formState.vend.integration.pipeLockPath}
                                        onChange={handleInputChange}
                                    />
                                    <TextRow
                                        label="Dodatkowa autoryzacja użytkownika podczas realizacji żądania"
                                        caption="VSD_PIPE_UNLOCK_PATH"
                                        name="vend.integration.pipeUnlockPath"
                                        value={formState.vend.integration.pipeUnlockPath}
                                        onChange={handleInputChange}
                                    />
                                    <TextRow
                                        label="Automatyczna finalizacja żądań"
                                        caption="VSD_LOCK_FILES_PATH"
                                        name="vend.integration.lockFilesPath"
                                        value={formState.vend.integration.lockFilesPath}
                                        onChange={handleInputChange}
                                    />
                                </React.Fragment>
                            )}
                        </TabPanel>
                        <TabPanel value="10" sx={{ p: 0 }} className="configuration-option">
                            <ListRow
                                label="Typ ekranu bezczynności"
                                caption="UI_IDLE_SCR_TYPE"
                                name="vend.frontend.idleScreenType"
                                value={formState.vend.frontend.idleScreenType}
                                onChange={handleSelectChange}
                                items={['clock', 'slideshow']}
                            />
                            {formState.vend.frontend.idleScreenType === 'slideshow' && (
                                <React.Fragment>
                                    <NumberRow
                                        label="Czas trwania prezentacji"
                                        caption="UI_IDLE_SCR_SLIDE_PERIOD"
                                        name="vend.frontend.slideshowPeriod"
                                        value={formState.vend.frontend.slideshowPeriod}
                                        onChange={handleInputChange}
                                    />
                                    <TextRow
                                        label="Ścieżka do prezentacji"
                                        caption="UI_SLIDES_PATH"
                                        name="vend.frontend.slidesPath"
                                        value={formState.vend.frontend.slidesPath}
                                        onChange={handleInputChange}
                                    />
                                </React.Fragment>
                            )}

                            <SwitchRow
                                label="Ukryj nieaktywne elementy menu"
                                caption="UI_HIDE_INACTIVE_MENU_ITEMS"
                                switchName="vend.frontend.hideInactiveMenu"
                                checked={formState.vend.frontend.hideInactiveMenu}
                                onChange={handleInputChange}
                            />
                            <ListRow
                                label="Liczba kolumn w liście produktów"
                                caption="UI_PRODLIST_COLNUM"
                                name="vend.frontend.productListColumn"
                                value={formState.vend.frontend.productListColumn}
                                onChange={handleSelectChange}
                                items={['1', '2']}
                            />
                            <ListRow
                                label="Liczba kolumn w katalogu produktów"
                                caption="UI_PRODCATALOG_COLNUM"
                                name="vend.frontend.productCatalogColumn"
                                value={formState.vend.frontend.productCatalogColumn}
                                onChange={handleSelectChange}
                                items={['1', '2']}
                            />
                            <ListRow
                                label="Liczba wierszy w katalogu produktów"
                                caption="UI_PRODCATALOG_ROWNUM"
                                name="vend.frontend.productCatalogRow"
                                value={formState.vend.frontend.productCatalogRow}
                                onChange={handleSelectChange}
                                items={['1', '2', '3', '4', '5', '6', '7']}
                            />
                            <NumberRow
                                label="Liczba kolumn w liście transakcji"
                                caption="UI_TRANSACTIONS_LIST_COLNUM"
                                name="vend.frontend.transactionsListColumn"
                                value={formState.vend.frontend.transactionsListColumn}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Liczba wierszy w liście transakcji"
                                caption="UI_TRANSACTIONS_LIST_ROWNUM"
                                name="vend.frontend.transactionsListRow"
                                value={formState.vend.frontend.transactionsListRow}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Liczba kolumn w liście użytkowników"
                                caption="UI_USERS_LIST_COLNUM"
                                name="vend.frontend.usersListColumn"
                                value={formState.vend.frontend.usersListColumn}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Liczba wierszy w liście użytkowników"
                                caption="UI_USERS_LIST_ROWNUM"
                                name="vend.frontend.usersListRow"
                                value={formState.vend.frontend.usersListRow}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż nazwy użytkowników na liście użytkowników"
                                caption="UI_SHOW_USER_NAMES_ON_USERS_LIST"
                                switchName="vend.frontend.showUserNames"
                                checked={formState.vend.frontend.showUserNames}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Zmiana języka użytkownika"
                                caption="UI_SWITCH_USER_LANGUAGE"
                                switchName="vend.frontend.switchUserLanguage"
                                checked={formState.vend.frontend.switchUserLanguage}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Opóźnienie filtru wzorca"
                                caption="UI_PATTERN_FILTER_DELAY"
                                name="vend.frontend.patternFilterDelay"
                                value={formState.vend.frontend.patternFilterDelay}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Rozdzielczość (DPI)"
                                caption="UI_DPI"
                                name="vend.frontend.dpiResolution"
                                value={formState.vend.frontend.dpiResolution}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż informacje zwrotne podczas aktualizacji pamięci podręcznej"
                                caption="UI_SHOW_DS_CACHE_UPDATE_PENDING"
                                switchName="vend.frontend.showCacheUpdate"
                                checked={formState.vend.frontend.showCacheUpdate}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Ukryj widget konfiguracji katalogu produktów"
                                caption="UI_HIDE_PRODUCT_CATALOG_CONFIG_WIDGET"
                                switchName="vend.frontend.hideProductCatalog"
                                checked={formState.vend.frontend.hideProductCatalog}
                                onChange={handleInputChange}
                            />

                            <TextRow
                                label="Ścieżka do zdjęć"
                                caption="UI_IMAGES_PATH"
                                name="vend.frontend.imagesPath"
                                value={formState.vend.frontend.imagesPath}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Pokaż licznik FPS"
                                caption="UI_SHOW_FPS_COUNTER"
                                switchName="vend.frontend.showFpsCounter"
                                checked={formState.vend.frontend.showFpsCounter}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="11" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Dezaktywuj interfejs CAN"
                                caption="VM_NO_CAN_IFACE"
                                switchName="vend.backend.noCanInterface"
                                checked={formState.vend.backend.noCanInterface}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Limit czasu dostępu do komórki"
                                caption="VM_US_INACT_TOUT"
                                name="vend.backend.cellAccessTimeLimit"
                                value={formState.vend.backend.cellAccessTimeLimit}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Limit czasu otwarcia komórki"
                                caption="VM_US_CELL_OPENED_TOUT"
                                name="vend.backend.cellOpeningTimeLimit"
                                value={formState.vend.backend.cellOpeningTimeLimit}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Sprawdź przed zamknięciem komórek"
                                caption="VM_CELLS_CLOSED_PRE_CHECK"
                                switchName="vend.backend.checkBeforeClosingCells"
                                checked={formState.vend.backend.checkBeforeClosingCells}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Wyłącz blokadę l40 w przypadku nielegalnego otwarcia"
                                caption="VM_L40_LOCK_ON_ILLEGAL_OPEN_DISABLED"
                                switchName="vend.backend.lockOnIllegalOpen"
                                checked={formState.vend.backend.lockOnIllegalOpen}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Rejestruj nowe transakcje"
                                caption="VM_LOG_NEW_TRANSACTION"
                                switchName="vend.backend.logNewTransactions"
                                checked={formState.vend.backend.logNewTransactions}
                                onChange={handleInputChange}
                            />
                            <NumberRow
                                label="Limit czasu wydłużenia flex"
                                caption="VM_FLEX_EJECTION_TIMEOUT"
                                name="vend.backend.flexEjectionTimeout"
                                value={formState.vend.backend.flexEjectionTimeout}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                        <TabPanel value="12" sx={{ p: 0 }} className="configuration-option">
                            <SwitchRow
                                label="Uzupełnienie"
                                caption="VM_CELLS_REPLENISHMENT"
                                switchName="vend.other.cellReplenishment"
                                checked={formState.vend.other.cellReplenishment}
                                onChange={handleInputChange}
                            />
                            <SwitchRow
                                label="Przesunięcia magazynowe"
                                caption="VM_INVENTORY_TRANSFER_ENABLED"
                                switchName="vend.other.inventoryTransfer"
                                checked={formState.vend.other.inventoryTransfer}
                                onChange={handleInputChange}
                            />
                        </TabPanel>
                    </CardContent>
                </Card>
            </TabContext>
        </Box>
    )
}

export default ApplicationTab

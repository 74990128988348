import { createContext, useContext } from "react";
import { DeviceThing } from "../api/api";

interface MachinesContextType {
	machines: DeviceThing[];
	isLoading: boolean;
}

const MachinesContext = createContext<MachinesContextType>({
	machines: [],
	isLoading: true,
});

export const useMachinesContext = () => useContext(MachinesContext);

export default MachinesContext;

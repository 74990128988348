import React from "react";
import {
	Box,
	Typography,
	Select,
	MenuItem,
	SelectChangeEvent,
} from "@mui/material";

interface ListRowProps {
	label: string;
	caption: string;
	name: string;
	value: string | null;
	items: string[];
	onChange: (event: SelectChangeEvent<string>) => void;
}

const ListRow: React.FC<ListRowProps> = ({
	label,
	caption,
	name,
	value,
	items,
	onChange,
}) => {
	const selectValue = value ?? "";
	return (
		<Box className="configuration-row">
			<Box>
				<Typography variant="body1">{label}</Typography>
				<Typography variant="caption">{caption}</Typography>
			</Box>
			<Select
				name={name}
				value={selectValue}
				onChange={onChange}
				sx={{ width: 130 }}
			>
				{items.map((item) => (
					<MenuItem key={item} value={item}>
						{item}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default ListRow;

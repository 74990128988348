import React from "react";
import { Box, Typography, TextField } from "@mui/material";

interface NumberRowProps {
	label: string;
	caption: string;
	name: string;
	value: number | null;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberRow: React.FC<NumberRowProps> = ({
	label,
	caption,
	name,
	value,
	onChange,
}) => {
	return (
		<Box className="configuration-row">
			<Box>
				<Typography variant="body1">{label}</Typography>
				<Typography variant="caption">{caption}</Typography>
			</Box>
			<TextField
				name={name}
				type="number"
				sx={{ width: 100 }}
				value={value}
				onChange={onChange}
			/>
		</Box>
	);
};

export default NumberRow;

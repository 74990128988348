import { Iot, IotData } from "aws-sdk";
import { createContext, useContext } from "react";

interface AppContextType {
	awsInstanceIot: Iot | null;
	awsInstanceIotData: IotData | null;
}

const AppContext = createContext<AppContextType>({
	awsInstanceIot: null,
	awsInstanceIotData: null,
});

export const useAppContext = () => useContext(AppContext);

export default AppContext;

import { Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import { useMachinesContext } from '../../context/MachinesContext'
import { useSearch } from '../../hooks/useSearch'

const columns: GridColDef[] = [
    {
        field: 'instance',
        headerName: 'Instancja',
        flex: 1,
    },
    {
        field: 'thingName',
        headerName: 'Numer seryjny',
        flex: 1,
    },
    {
        field: 'version',
        headerName: 'Wersja oprogramowania',
        type: 'number',
        flex: 1,
    },
    {
        field: 'lastUpdateString',
        headerName: 'Ostatnia aktualizacja',
        description: 'This column has a value getter and is not sortable.',
        flex: 1,
    },
];

const MachinesList = () => {
    const navigate = useNavigate();
    const { machines, isLoading } = useMachinesContext();
    const filteredMachines = useSearch(machines);

    const openMachineDetails = (selectedMachine: any) => {
        navigate(`/machineConfiguration/${selectedMachine.id}`)
    }

    // Display a loading indicator while fetching data
    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={filteredMachines}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[25]}
                    disableColumnSelector
                    disableRowSelectionOnClick
                    onRowClick={(rowData) => openMachineDetails(rowData)}
                    getRowId={(machine) => machine.thingName}
                    sx={{
                        backgroundColor: 'white',
                        border: 'none',
                        borderRadius: 0,
                        '.MuiDataGrid-cell:first-of-type': {
                            textIndent: 40,
                        },
                        '.MuiDataGrid-cell': {
                            fontSize: 16,
                        },
                        '.MuiDataGrid-columnHeader:first-of-type': {
                            textIndent: 40,
                        },
                        '.MuiDataGrid-columnHeaders': {
                            backgroundColor: '#F7F8F9',
                        },
                        '.MuiDataGrid-columnHeader': {
                            color: '#00000099',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    rowHeight={75}
                />
            </Box>
        </Box>
    )
}

export default MachinesList

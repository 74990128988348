import { useState, useEffect } from "react";
import { fetchIoTDevices } from "../api/machinesApi";
import { DeviceThing } from "../api/api";
import { useAppContext } from "../context/AppContext";

export function useFetchIoTDevices(): [DeviceThing[], boolean, Error | null] {
	const [machines, setMachines] = useState<DeviceThing[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);
	const { awsInstanceIot, awsInstanceIotData } = useAppContext();

	useEffect(() => {
		if (!awsInstanceIot || !awsInstanceIotData) {
			setIsLoading(false);
			setError(new Error("aws instance is null"));
			return;
		}
		fetchIoTDevices(awsInstanceIot, awsInstanceIotData)
			.then((data) => {
				setMachines(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setError(error);
			});
	}, []);

	return [machines, isLoading, error];
}

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: `8px`,
	border: `1px solid ${theme.palette.grey[300]}`,
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.black, 0.01),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(3),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	right: 0,
	top: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em)})`,
		paddingRight: `calc(1em + ${theme.spacing(3)})`,
		transition: theme.transitions.create("width"),
		width: "30vw",
		[theme.breakpoints.up("md")]: {
			width: "50ch",
		},
	},
}));

const SearchBar = (props: { onSearch: (value: string) => void }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const initialSearchValue = queryParams.get("search") || "";

	const [searchValue, setSearchValue] = useState(initialSearchValue);

	useEffect(() => {
		setSearchValue(initialSearchValue);
	}, [initialSearchValue]);

	const handleInputChange = (event: any) => {
		const value = event.target.value;
		setSearchValue(value);
		if (props.onSearch) {
			props.onSearch(value);
		}
		navigate(`?search=${value}`);
	};

	return (
		<Search>
			<StyledInputBase
				placeholder="Szukaj"
				inputProps={{ "aria-label": "search" }}
				value={searchValue}
				onChange={handleInputChange}
			/>
			<SearchIconWrapper>
				<SearchIcon />
			</SearchIconWrapper>
		</Search>
	);
};

export default SearchBar;
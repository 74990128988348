import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../../resources/logo.png";
import Title from "../../resources/title.svg";
import { Box, Divider, IconButton, Menu } from "@mui/material";
import SearchBar from "../SearchBar/SearchBar";
import { Link, useLocation } from "react-router-dom";
import { Fragment, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
interface HeaderProps {
	isLoggedIn: boolean;
	onLogout: () => void;
}

const Header = ({ isLoggedIn, onLogout }: HeaderProps) => {
	const location = useLocation();
	const isHomePage = location.pathname === "/";

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [searchQuery, setSearchQuery] = useState("");

	const handleSearch = (value: string) => {
		setSearchQuery(value);
		console.log(searchQuery);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		onLogout();
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="sticky" color="secondary" sx={{ boxShadow: "none" }}>
			<Toolbar
				sx={{
					height: { xs: 100, lg: 45 },
					minHeight: { lg: 45 },
					margin: "auto",
					display: "flex",
					flexDirection: { xs: "column", lg: "row" },
					justifyContent: "space-between",
					width: { md: "90%", lg: "75%" },
					padding: 2,
				}}
			>
				<Link to="/">
					<Box display="flex" alignItems="center" gap={3}>
						<Box component="img" alt="Logo" src={Logo} />
						<Box
							component="img"
							alt="title"
							src={Title}
							sx={{ height: "25px" }}
						/>
					</Box>
				</Link>
				<Box display="flex" sx={{ marginRight: -9 }}>
					{isHomePage && (
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<SearchBar onSearch={handleSearch} />
						</Box>
					)}
					{isLoggedIn && (
						<Fragment>
							<IconButton
								size="small"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<AccountCircle style={{ fontSize: "40px" }} />
							</IconButton>
							<Menu
								sx={{ marginTop: 7, marginLeft: -1 }}
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
							</Menu>
						</Fragment>
					)}
				</Box>
			</Toolbar>
			<Divider />
		</AppBar>
	);
};

export default Header;

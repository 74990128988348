import React from "react";
import { Card, CardContent, Box, Tab } from "@mui/material";
import { TabList, TabPanel } from "@mui/lab";
import { FormState } from "../../interfaces/index";
import TextRow from "../Rows/TextRow";
import SwitchRow from "../Rows/SwitchRow";

interface TabProps {
	formState: FormState;
	handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NetworkTab = ({ formState, handleInputChange }: TabProps) => {
	return (
		<Box className="sub-card-container" sx={{ height: "100%" }}>
			<TabList orientation="vertical" sx={{ width: "174px" }}>
				<Tab
					label="Ethernet"
					value="2"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="GSM"
					value="1"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="DNS"
					value="3"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="NTP"
					value="4"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="PING"
					value="5"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
				<Tab
					label="VPN"
					value="6"
					className="sub-card-tab"
					sx={{ color: "#858585" }}
				/>
			</TabList>
			<Card
				sx={{ width: "77%", p: 0 }}
				variant="outlined"
				className="card-configuration"
			>
				<CardContent sx={{ p: 2 }} className="card-configuration">
					<TabPanel value="2" sx={{ p: 0 }} className="configuration-option">
						<SwitchRow
							label="Włącz Ethernet"
							caption="x"
							switchName="connman.ethernet.ethernet"
							checked={formState.connman.ethernet.ethernet}
							onChange={handleInputChange}
						/>

						<SwitchRow
							label="DHCP"
							caption="x"
							switchName="connman.ethernet.dhcp"
							checked={formState.connman.ethernet.dhcp}
							onChange={handleInputChange}
						/>

						<TextRow
							label="Adres IP"
							caption="x"
							name="connman.ethernet.ipAddress"
							value={formState.connman.ethernet.ipAddress}
							onChange={handleInputChange}
						/>

						<TextRow
							label="Adres MAC"
							caption="x"
							name="connman.ethernet.macAddress"
							value={formState.connman.ethernet.macAddress}
							onChange={handleInputChange}
						/>
					</TabPanel>
				</CardContent>
			</Card>
		</Box>
	);
};

export default NetworkTab;

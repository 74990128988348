import AWS from "aws-sdk";

interface UserCredentials {
	accessKey: string;
	secretAccessKey: string;
	sessionToken?: string;
}

export function createAWSInstance(credentials: UserCredentials): typeof AWS {
	const { accessKey, secretAccessKey, sessionToken } = credentials;

	AWS.config.update({
		accessKeyId: accessKey,
		secretAccessKey: secretAccessKey,
		// region: process.env.REACT_APP_AWS_REGION,
		sessionToken: sessionToken,
		region: "eu-central-1",
	});

	return AWS;
}

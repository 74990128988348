import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#009AFF",
		},
		secondary: {
			main: "#FFFFFF",
		},
		error: {
			main: "#FF0000",
		},
		text: {
			primary: "#000000",
			secondary: "#0083D9",
		},
	},
	typography: {
		caption: {
			color: "#b6b6b6",
			fontSize: 12,
		},
		body1: {
			color: "#000000DE",
			fontSize: 16,
		},
		h5: {
			color: "#414141",
			fontSize: 24,
			fontWeight: 600,
		},
	},
});

export default theme;

import React from "react";
import { Box, Typography, Switch } from "@mui/material";

interface SwitchRowProps {
	label: string;
	caption: string;
	switchName: string;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchRow: React.FC<SwitchRowProps> = ({
	label,
	caption,
	switchName,
	checked,
	onChange,
}) => {
	return (
		<Box className="configuration-row">
			<Box>
				<Typography variant="body1">{label}</Typography>
				<Typography variant="caption">{caption}</Typography>
			</Box>
			<Switch name={switchName} checked={checked} onChange={onChange} />
		</Box>
	);
};

export default SwitchRow;
